jQuery(function($) {
  var win = $(window);
  var body = $('body');
  var header = $('.header');
  var nav = $('.header__nav');
  var back = $('.header__small__back__text');



  var openMobileMenu = function() {
    body.addClass('menu-active');
    console.log('open menu');
  }

  var closeMobileMenu = function() {
    body.removeClass('menu-active');
    console.log('close menu');
  }

  var toggleMobileMenu = function() {
    if (body.hasClass('menu-active')) {
      closeMobileMenu();
    }
    else {
      openMobileMenu();
      closeSearch();
    }
  }

  var openSearch = function() {
    body.addClass('search-active');
    setTimeout(function(){
      $('.header__search__input').focus();
    }, 500)

  }

  var closeSearch = function() {
    body.removeClass('search-active');
  }

  var toggleSearch = function() {
    if (body.hasClass('search-active')) {
      closeSearch();
    }
    else {
      openSearch();
      closeMobileMenu();
    }
  }

  var openInfoMenu = function() {
    body.addClass('info-active');
  }

  var closeInfoMenu = function() {
    body.removeClass('info-active');
  }

  var toggleInfoMenu = function() {
    if (body.hasClass('info-active')) {
      closeInfoMenu();
    }
    else {
      openInfoMenu();
    }
  }

  $('.header__small__menu-toggle').on('click', toggleMobileMenu);


  $('.header__small__search-toggle').on('click', toggleSearch);
  $('.header__nav__search').on('click', toggleSearch);
  $('.header__search__close').on('click', closeSearch);

  $('.header__nav__info').on('click', toggleInfoMenu);
  $('.header__nav__info__close').on('click', closeInfoMenu);

  $('.header__small__back').on('click', function(e) {
    header.removeClass('header--back');
    $('.nav .expanded').removeClass('expanded').find('ul').attr('aria-hidden', 'true');
    e.preventDefault();
  });

  $('.nav').on('click', '.header__nav__toggle', function(e) {
    var item = $(this).parent();
    var text = $(this).prev().text();
    if ($('> .menu', item).length) {
      back.text(text);
      $('.nav .expanded').not(item).removeClass('expanded');
      if (item.hasClass('expanded')) {
        item.removeClass('expanded');
        item.find('ul').attr('aria-hidden', 'true');
        header.removeClass('header--back');
      } else {
        item.addClass('expanded');
        item.find('ul').attr('aria-hidden', 'false');
        header.addClass('header--back');
      }
      e.preventDefault();
    }
  });


});

