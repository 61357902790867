jQuery(function($) {
  function windowSize() {
    windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
    windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
  }
  $(window).on('load resize', function() {
    windowSize();
    if (windowWidth >= 767) {
      $('.event__item--match').matchHeight();
    }
  })





});
