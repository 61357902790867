jQuery(function($) {
  $('.form__select').each(function() {
    var container = $(this);
    var text = $('.form__select__text', container);
    var select = $('select', container);
    var selectedText = $('option:selected', select).text();
    text.text(selectedText);
    select.on('change', function() {
      var selectedText = $('option:selected', select).text();
      text.text(selectedText);
    });

    select.on('focus', function() {
      $('.form__select__text', container).addClass('form__select__text--focus');
    });
    select.on('blur', function() {
      $('.form__select__text', container).removeClass('form__select__text--focus');
    });
  });
  
});
