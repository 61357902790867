jQuery(function($) {

    $('.quote-slide-js').slick({
      slide: '.quote-slide__item',
      appendArrows: '.quote__slider__nav',
      nextArrow: '<button aria-label="next" class="slider__nav--right slick-next slick-arrow"><span class="hide">Next</span></button>',
      prevArrow: '<button aria-label="prev" class="slider__nav--left slick-prev slick-arrow"><span class="hide">Previous</span></button>',
      centerMode: true,
      centerPadding: '220px',
      slidestoShow: 1,
      rows: 0,
      responsive: [ {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          variableWidth: false
        }
      }]
    });



    $('.quote-slide--white').slick({
      slide: '.quote-slide__white__item',
      // fade: true,
      appendArrows: '.quote__slider__nav--white',
      nextArrow: '<button aria-label="next" class="slider__nav--right slick-next slick-arrow"><span class="hide">Next</span></button>',
      prevArrow: '<button aria-label="prev" class="slider__nav--left slick-prev slick-arrow"><span class="hide">Previous</span></button>',
      rows: 0
    });


});
