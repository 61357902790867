jQuery(function($) {
  $('.subnav li').addClass('subnav__item');
  $('.subnav li a').addClass('subnav__link');
  $('.subnav li ul li a').addClass('subnav__link--nested');
  $('.subnav li ul').addClass('subnav').addClass('subnav--nested');
  $('.subnav li').children('ul').parent().prepend(
    '<a href="#" class="subnav__toggle">' +
    '<span class="hide">Toggle</span>' +
    '<span class="subnav__toggle--open"><span class="svgstore svgstore--SubNav_Open"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#SubNav_Open"></use></svg></span></span>' +
    '<span class="subnav__toggle--close"><span class="svgstore svgstore--SubNav_Close"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#SubNav_Close"></use></svg></span></span>' +
    '</a>'
    );

  $('.subnav').fadeIn(1);

  $('.subnav__item')
  .find('.subnav__link')
  .find('.currentsection')
  .parent().parent().addClass('subnav__item--active');

  $('.subnav__link--nested').attr('tabindex', '-1');

  $('.subnav__item--active').find('.subnav__link--nested').attr('tabindex', '0');



  var nestedSubnavToggle = function(elem) {
    $(elem).parent().toggleClass('subnav__item--active');
    if ($(elem).parent().hasClass('subnav__item--active')) {
      $(elem).parent().find('.subnav__link--nested').each(function(){
        $(this).attr('tabindex', '0');
      });
    }
  }

  $('.subnav__toggle').on('click', function(e) {
    $('.subnav__link--nested').each(function(){
      $(this).attr('tabindex', '-1');
    });

    nestedSubnavToggle(this);
    e.preventDefault();
  });

});
