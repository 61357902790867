jQuery(function($) {
  if (window.innerWidth >= 767) {
    $('.feature__match-height').matchHeight();
  }



  // Autoplay video no supported in iOS devices so hide
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var android = /Android/.test(navigator.userAgent);

  if(iOS || android) {
    if (document.querySelector('.feature__hero__video')!=null) {
      document.querySelector('.feature__hero__video').style.display = 'none';
      var heroBackgroundImage = document.querySelector('.feature__hero--video').getAttribute('data-background-image');

      document.querySelector('.feature__hero--video').style.backgroundImage = 'url(' + heroBackgroundImage + ')';
    }
  }


});
