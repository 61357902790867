// fastclick eliminates click delay in certain browsers
var fastclick = require('fastclick');
fastclick(document.body);

// svg polyfill for better xlink support
var svg4everybody = require('svg4everybody');
svg4everybody();

// global jquery
global.jQuery = require('jquery');

// placeholder attribute polyfill
require('jquery-placeholder');
jQuery('input, textarea').placeholder();


// match height
require('./util/_match-height');

// object-fit polyfill
var objectFitImages = require('object-fit-images');
objectFitImages();

// srcset picture polyfill
require('respimage');

// Slick slider
require('slick-carousel');

// automatically require all modules
require('./modules/_accordion.js');require('./modules/_dropdown.js');require('./modules/_events.js');require('./modules/_feature.js');require('./modules/_finder.js');require('./modules/_form.js');require('./modules/_header.js');require('./modules/_hero.js');require('./modules/_juicer.js');require('./modules/_modal.js');require('./modules/_nav.js');require('./modules/_news.js');require('./modules/_quote-slide.js');require('./modules/_subnav.js');require('./modules/_table.js');require('./modules/_tabs.js');require('./modules/_timeline.js');
