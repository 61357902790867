jQuery(function($) {
  $('.timeline__group__item').on('click', function() {
    var testimonialNumber = $(this).attr('data-testimonial');

    $('.timeline__group__item').removeClass('active');
    $(this).addClass('active');

    $('.timeline__wrap').each(function(idx, item) {
      if($(item).attr('data-testimonial') == testimonialNumber) {
        $(item).addClass('active');
      }
      else {
        $(item).removeClass('active');
      }
    });
  });

  // timeline slider
  if (window.innerWidth <= 767) {
    $('.timeline__group').slick({
      arrows: false,
      slidesToShow: 2,
      variableWidth: true,
      centerMode: true,
      responsive: [ {
        breakpoint: 480,
        settings: {
          centerMode: false
        }
      }]
    });
  }

  $(window).on('load resize', function() {
    $('.timeline__group__item').removeClass('active');
    $('.timeline__wrap').removeClass('active');

    if(window.innerWidth <= 767) {
      $('.mobile-default').addClass('active');
    }
    else {
      $('.desktop-default').addClass('active');
    }
  });

});

