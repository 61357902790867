jQuery(function($) {
  var toggleAccordion = function(elem) {
    var accordionToggle = $(elem);
    var accordionContent = $(elem).next('.accordion__content');
    $(accordionContent).toggleClass('accordion__content--active');

    $(accordionToggle).toggleClass('accordion__toggle--active');
  }

  $('.accordion__toggle').on('click', function(e) {
    e.preventDefault();

    toggleAccordion(this);
  })
});
