var $ = require("jquery");
require("datatables.net")(window, $);
require("datatables.net-responsive")(window, $);

jQuery(function($) {
  // Global Table
  var dt = $("table.dataTable");
  for (i = 0; i < dt.length; i++) {
    dt[i].id = "directory-datatable";

    var dTable = $("#directory-datatable").DataTable({
      searchCols: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        { search: ".", regex: true }
      ],
      lengthChange: false,
      language: {
        search: ""
      },
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
      columnDefs: [
        { orderable: false, aTargets: ["noSort"] },
        { visible: false, aTargets: ["dt-hidden-column"] }
      ],
      order: [[1, "asc"]],
      responsive: true
    });

    var label = $("#directory-datatable" + "_filter label");
    label.attr("for", "directory-datatable" + "_search");
    label.addClass("search-label");

    var wrapSpan = $("<span>Search</span>");
    wrapSpan.appendTo(label);

    var input = $("#directory-datatable" + "_filter input");
    input.attr("placeholder", "Search");
    input.attr("id", "directory-datatable" + "_search");
    input.addClass("form__input");
    input.attr("title", "Search Department Table");

    // Search dropdown for department
    $("#dept-form-select").on("change", function() {
      var selectedText = $("option:selected").val();
      if (selectedText == "null") {
        dTable.column(8).search(".", true); // Only show results with a value in 9th column
        dTable
          .column(5)
          .search("")
          .draw();
      } else {
        var newVal = $.fn.dataTable.util.escapeRegex(selectedText);
        dTable
          .column(8)
          .search(""); // Show all results
        dTable
          .column(5)
          .search(newVal ? "^" + newVal + "$" : "", true, false)
          .draw();
      }
    });
  }

  // Toggle search hidden field
  $(".table__faculty__toggle").on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).text("View Faculty Only");
      dTable.search("").draw();
    } else {
      $(this).addClass("active");
      $(this).text("View Faculty and Staff");
      dTable.search("Faculty").draw();
    }
  });
});
