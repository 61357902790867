jQuery(function($) {
  var win = $(window);
  var body = $('body');
  var header = $('.header');
  var nav = $('.header__nav');
  var back = $('.header__small__back__text');


  $.fn.setup_navigation = function(b) {
    b = jQuery.extend({
      menuHoverClass: 'show-menu'
    }, b);
    $(this)
    .attr('role', 'menubar')
    .find('li')
    .attr('role', 'menuitem');

    var link = $(this).find('> li > a');

    $(link)
    .next('ul')
    .attr({
      'aria-hidden': 'true',
      role: 'menu'
    })
    .find('a')
    .attr('tabIndex', -1);


    $(link).each(function() {
      if ($(this).next('ul').length > 0) {
        $(this).parent('li').attr('aria-haspopup', 'true')
      }
    });


    $(link).on('focus', function() {
      $(this)
      .closest('ul')
      .attr('aria-hidden', 'false')
      .find('.' + b.menuHoverClass)
      .attr('aria-hidden', 'true')
      .removeClass(b.menuHoverClass)
      .find('a')
      .attr('tabIndex', -1);

      $(this)
      .next().next()
      .attr('aria-hidden', 'false')
      .addClass(b.menuHoverClass)
      .find('a').attr('tabIndex', 0)
    });

    $(this)
    .find('a')
    .last()
    .keydown(function(key) {
      if (key.keyCode == 9) {
        $('.' + b.menuHoverClass)
        .attr('aria-hidden', 'true')
        .removeClass(b.menuHoverClass)
        .find('a')
        .attr('tabIndex', -1)
      }
    });

    $(document).click(function() {
      $('.' + b.menuHoverClass)
      .attr('aria-hidden', 'true')
      .removeClass(b.menuHoverClass)
      .find('a')
      .attr('tabIndex', -1)
    });

    $(this).click(function(key) {
      key.stopPropagation()
    });
  };

  $(function() {
    $('.nav').setup_navigation()
  });

});
