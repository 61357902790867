jQuery(function($) {
  $('.hero__slide__img').slick({
    fade: true,
    arrows: false,
  });

  $('.hero__slide__container').slick({
    adaptiveHeight: true,
    fade: true,
    rows: 0,
    slide: '.hero__slide__content',
    asNavFor: '.hero__slide__img',
    appendArrows: '.slider__nav',
    nextArrow: '<button aria-label="next" class="slider__nav--right slick-next slick-arrow"><span class="hide">Next</span></button>',
    prevArrow: '<button aria-label="prev" class="slider__nav--left slick-prev slick-arrow"><span class="hide">Previous</span></button>',

  });


  // Autoplay video no supported in iOS devices so hide
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var android = /Android/.test(navigator.userAgent);

  if(iOS || android) {
    if (document.querySelector('.hero__video')!=null) {
      document.querySelector('.hero__video').style.display = 'none';
      var heroBackgroundImage = document.querySelector('.hero--video').getAttribute('data-background-image');
      var img = document.createElement('img');
      img.src = heroBackgroundImage;
      document.querySelector('.hero--video').appendChild(img);
    }
  }
});
