jQuery(function($) {

  $('.tabs__nav__link').on('click', function(e) {
    var index = $(this).parent().index();
    var tabs = $(this).closest('.tabs');
    var navItems = $('.tabs__nav__item', tabs);
    var mainItems = $('.tabs__main__item', tabs);
    navItems.removeClass('tabs__nav__item--active');
    navItems.eq(index).addClass('tabs__nav__item--active');
    mainItems.removeClass('tabs__main__item--active');
    mainItems.eq(index).addClass('tabs__main__item--active');
    e.preventDefault();
  });

  $('.tabs__main__toggle').on('click', function(e) {
    var item = $(this).parent();
    item.toggleClass('tabs__main__item--active--accordion');
    e.preventDefault();
  });

});
