jQuery(function($) {


  $('.finder__dropdown__button').on('click', function(e) {
    $(this).parent().toggleClass('finder__dropdown--active');
    e.preventDefault();
  });

  $(document).on('click', function(e) {
    $('.finder__dropdown--active').each(function() {
      var container = $(this);
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('finder__dropdown--active');
      }
    });
  });

  var degreeDropdown  = $('.finder__dropdown__list--degree');
  var locationDropdown  = $('.finder__dropdown__list--location');
  var schoolDropdown = $('.finder__dropdown__list--school');
  var searchBox       = $('.finder__search__input');

  var dropdownSelect = function(elem) {
    var dataValue = $(elem).attr('data-filter-opt') || $(elem).text();
    $(elem).closest('.finder__dropdown__list').attr('data-filter-dropdown', dataValue);
    $(elem).closest('.finder__dropdown').removeClass('finder__dropdown--active')
  }

  $('.finder__dropdown__link').on('click', function(e) {
    dropdownSelect(this);
    filter();
    e.preventDefault();
  });

  var clearDropdowns = function() {
    $('.finder__dropdown__list').each(function() {
      $(this).removeAttr('data-filter-dropdown');
    });
  }

  var clearInput = function() {
    searchBox.val('');
  }

  $('.clear-filter').on('click', function() {
    clearDropdowns();
    clearInput();
    filter();
    $(this).hide();

  });

  var filterOnDegree = function(degree, item) {
    if (degree && degree.length > 0) {
      var matches = false;
      $(item).find('.finder__card__degree').each(function(idx, item) {
        var degreeText = $(item).text();

        if (degreeText.indexOf(degree) == 0) {
          matches = true;
        }
      });
      return matches;
    }
    else {
      return true;
    }
  }

  var filterOnLocation = function(location, item) {
    if (location && location.length > 0) {
      var optionText = $(item).attr('data-filter-location');
      return optionText.indexOf(location.toLowerCase()) == 0;
    }
    else {
      return true;
    }
  }

  var filterOnSchool = function(school, item) {
    if (school && school.length > 0) {
      var optionText = $(item).attr('data-filter-school');
      return optionText.indexOf(school) >= 0;
    }
    else {
      return true;
    }
  };

  var filterOnSearch = function(search, item) {
    if (search && search.length > 0) {
      var searchText = $(item).find('.finder__card__link').text().toLowerCase();
      return searchText.indexOf(search.toLowerCase()) >= 0;
    }
    else {
      return true;
    }
  };

  var filter = function() {
    $('.finder__title').empty();

    $('.finder__grid').empty()
    .append($('<div></div>').addClass('finder__grid__left'))
    .append($('<div></div>').addClass('finder__grid__right'));


    var degreeSelected   = degreeDropdown.attr('data-filter-dropdown');
    var locationSelected = locationDropdown.attr('data-filter-dropdown');
    var schoolSelected   = schoolDropdown.attr('data-filter-dropdown');
    var searchEntered    = searchBox.val();

    var newTitle = '';

    if (degreeSelected) {
      newTitle = newTitle + 'Degree: ' + degreeSelected;
    }

    if (locationSelected) {
      if (newTitle.length > 0) {
        newTitle = newTitle + ', ';
      }
      newTitle = newTitle + 'Location: ' + locationSelected;
    }

    if (schoolSelected) {
      if (newTitle.length > 0) {
        newTitle = newTitle + ', ';
      }
      newTitle = newTitle + 'School: ' + schoolSelected;
    }

    if (searchEntered) {
      if (newTitle.length > 0) {
        newTitle = newTitle + ', ';
      }
      newTitle = newTitle + 'Search: ' + searchEntered;
    }

    if (newTitle.length == 0) {
      newTitle = 'All Programs';
    }
    $('.finder__title').text(newTitle);


    $('.finder__grid--hidden')
    .children()
    .filter(function(idx, item) {
      return (
        filterOnDegree(degreeDropdown.attr('data-filter-dropdown'), item)     &&
        filterOnLocation(locationDropdown.attr('data-filter-dropdown'), item) &&
        filterOnSchool(schoolDropdown.attr('data-filter-dropdown'), item)     &&
        filterOnSearch(searchBox.val(), item)
        );
    })
    .sort(function(itemA, itemB) {
      var nameA = $(itemA).find('.finder__card__link').text();
      var nameB = $(itemB).find('.finder__card__link').text();

      if      (nameA > nameB) return  1;
      else if (nameA < nameB) return -1;
      else                    return  0;
    })
    .clone()
    .each(function(idx, item) {
      $(item).appendTo('.finder__grid__left');
    });

    $('.clear-filter').show();
  };


  $('.finder__search__input').on('propertychange change click keyup input paste', filter);

  filter();
  $('.clear-filter').hide();


});
