jQuery(function($) {


  if (window.innerWidth >= 767) {
    $('.news__match-height').matchHeight();
  }


  var gridSliderCreated = false;

  var createGridSlider = function(idx, item) {
    var carouselId = "carousel" + idx;
    item.id = carouselId;
    $(item).slick({
      slide: "#" + carouselId +" .grid__item",
      arrows: false,
      centerMode: true,
      centerPadding: '60px',
      rows: 0,
    });
  }

  var destroyGridSlider = function(idx, item) {
    $(item).slick('unslick');
  }

  var initGridSlider = function() {
    var screenSize = $(window).width();

    if (screenSize <= 767) {

      if (!gridSliderCreated) {
        gridSliderCreated = true;
        $('.grid__slider').each(createGridSlider);
      }
    }

    else if (gridSliderCreated) {
      gridSliderCreated = false;
      $('.grid__slider').each(destroyGridSlider);
    }
  }

  initGridSlider();

  $(window).resize(function() {
    initGridSlider();
  });


});
